<template>
    <div class="field_allegati">
        <div class="title">{{ title }}</div>
        <div class="allegati_container">
            <img v-for="(image, index) in attachments" :key="index" :src="setImageUrl(image)" alt="Allegato intervento" @click="imageClick(image)" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "FieldAllegati",
    props: {
        title: {
            type: String,
            required: true,
        },
        attachments: {
            type: Array,
            required: true,
        },
    },
    setup(props, { emit }) {
        const setImageUrl = (img) => {
            return `${process.env.VUE_APP_BASE_URL}/uploads/${img.path_local}`;
        };

        const imageClick = (image) => {
            emit("image-click", image);
        };

        return {
            setImageUrl,
            imageClick,
        };
    },
});
</script>

<style scoped>
.allegati_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
}
.allegati_container img {
    width: 200px;
    margin-right: 16px;
    border-radius: 8px;
}
.intervento .field_allegati {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px;
    border-bottom: 1px solid #e2e8f0;
}
.field_allegati .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field_allegati .title {
    margin-bottom: 8px;
}
.field_allegati .value {
    font-size: 14px;
}
</style>
