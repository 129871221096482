<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo intervento</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <!-- Customer list, triggered with click on select client in form -->
                <Transition name="fade-transition">
                    <div v-show="showCustomer" class="customer_modal">
                        <div class="customers_container">
                            <input type="text" placeholder="Cerca cliente" v-model="searchQuery" class="search_customers" />
                            <div class="customers_list">
                                <div
                                    v-for="customer in searchedCustomers"
                                    :key="customer.customers_id"
                                    @click="setSelectedCustomer(customer)"
                                    class="customer"
                                    :class="setActiveCustomer(customer, selectedCustomer)"
                                >
                                    {{ customer.customers_company ? customer.customers_company : `${customer.customers_name} ${customer.customers_last_name}` }}
                                </div>
                            </div>
                        </div>
                        <div @click="closeCustomerSelection()" class="close_modal">Chiudi</div>
                    </div>
                </Transition>

                <form @submit.prevent="creaIntervento()">
                    <ion-list class="fields">
                        <!-- Cliente -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title customer_selection">
                                    <span>
                                        Cliente
                                        <ion-text color="danger"><strong>*</strong></ion-text>
                                    </span>
                                    <div @click="openCustomerSelection()" class="trigger">seleziona</div>
                                </div>
                                <div class="value">
                                    <div v-if="selectedCustomer">
                                        {{
                                            selectedCustomer.customers_company
                                                ? selectedCustomer.customers_company
                                                : `${selectedCustomer.customers_name} ${selectedCustomer.customers_last_name}`
                                        }}
                                    </div>
                                    <div v-else>Nessun cliente selezionato</div>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Sede cliente -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Sede
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="sede_cliente"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="intervento.sede_cliente"
                                    >
                                        <ion-select-option
                                            v-for="sede in sedi_cliente"
                                            :key="sede.customers_shipping_address_id"
                                            :value="sede.customers_shipping_address_id"
                                        >
                                            {{ `${sede.customers_shipping_address_city}, ${sede.customers_shipping_address_street} ` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Progetti cliente -->
                        <!-- <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Progetti
                                </div>
                                <div class="value">
                                    <ion-select name="progetto" interface="action-sheet" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.progetto">
                                        <ion-select-option v-for="progetto in progetti" :key="progetto.projects_id" :value="progetto.projects_id">
                                            {{ `${progetto.projects_name}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item> -->

                        <!-- Modalità (Tipologia) -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Modalità
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select name="modalita" interface="action-sheet" cancel-text="Annulla" ok-text="Conferma" v-model="intervento.modalita">
                                        <ion-select-option
                                            v-for="tipo in modalita_intervento"
                                            :key="tipo.tickets_reports_type_id"
                                            :value="tipo.tickets_reports_type_id"
                                        >
                                            {{ `${tipo.tickets_reports_type_value}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Tipo intervento -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Tipo intervento
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="tipo_intervento"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="intervento.tipo_intervento"
                                        :placeholder="showPlaceholderTextTipoIntervento()"
                                        :compareWith="compareWith"
                                    >
                                        <ion-select-option
                                            v-for="(tipo, index) in tipi_intervento"
                                            :key="index"
                                            :value="tipo.tickets_reports_tipo_intervento_id"
                                        >
                                            {{ `${tipo.tickets_reports_tipo_intervento_value}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Maggiorazione -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Maggiorazione
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="maggiorazione"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="intervento.maggiorazione"
                                        :placeholder="showPlaceholderTextMaggiorazione()"
                                        :compareWith="compareWith"
                                    >
                                        <ion-select-option
                                            v-for="maggiorazione in maggiorazioni"
                                            :key="maggiorazione.tickets_reports_maggiorazione_id"
                                            :value="maggiorazione.tickets_reports_maggiorazione_id"
                                        >
                                            {{ `${maggiorazione.tickets_reports_maggiorazione_value}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data inizio -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="data"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2024"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="intervento.data_inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ora inizio -->
                        <div class="flex_container">
                            <div class="field">
                                <div class="field_title">
                                    Ora inizio
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_inizio"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="intervento.ora_inizio"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                            <div class="field">
                                <div class="field_title">
                                    Ora fine
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="ora_fine"
                                        display-format="HH:mm"
                                        picker-format="HH:mm"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="intervento.ora_fine"
                                        placeholder="Seleziona ora di inizio"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </div>

                        <!-- Tempo di viaggio -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Temp di viaggio (min)
                                </div>
                                <div class="value">
                                    <ion-input type="number" min="0" step="any" v-model="intervento.tempo_viaggio"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Ore da fatturare -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title">Ore da fatturare <small>(lasciare vuoto per calcolo automatico)</small></div>
                                <div class="value">
                                    <ion-input type="number" min="0" step="any" v-model="intervento.ore_fatturabili"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Stato lavori -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Stato lavori
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="stato_lavori"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="intervento.stato_lavori"
                                    >
                                        <ion-select-option
                                            v-for="stato in stati_lavoro"
                                            :key="stato.tickets_reports_stato_lavori_id"
                                            :value="stato.tickets_reports_stato_lavori_id"
                                        >
                                            {{ `${stato.tickets_reports_stato_lavori_value}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Tecnici -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Tecnici
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="tecnici"
                                        multiple="true"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="intervento.tecnici"
                                        :placeholder="showPlaceholderTextTecnici()"
                                        :compareWith="compareWith"
                                    >
                                        <ion-select-option v-for="tecnico in tecnici" :key="tecnico.users_id" :value="tecnico">
                                            {{ `${tecnico.users_first_name} ${tecnico.users_last_name}` }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Descrizione -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Descrizione
                                </div>
                                <div class="value">
                                    <ion-textarea
                                        type="text"
                                        rows="4"
                                        v-model="intervento.descrizione"
                                        placeholder="Descrizione lavoro svolto"
                                        class="custom_input"
                                    >
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Materiali -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">Materiali</div>
                                <div class="value">
                                    <ion-textarea type="text" rows="4" v-model="intervento.materiali_utilizzati" placeholder="Elenco dei materiali utilizzati">
                                    </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Allegati (foto) intervento-->
                        <ion-item lines="none" class="ion-no-padding" v-if="intervento.allegati.length != 0">
                            <div class="field">
                                <div class="field_title">Allegati</div>
                                <!--<div class="value">{{ pulizia.foto }}</div> -->
                                <div class="foto_container">
                                    <div v-for="(foto, index) in intervento.allegati" :key="index" class="single_foto">
                                        <ion-thumbnail>
                                            <img :src="`data:image/png;base64,${foto.data}`" />
                                        </ion-thumbnail>
                                        <div @click="deletePhoto(foto.name)" class="remove_photo">Rimuovi</div>
                                    </div>
                                </div>
                            </div>
                        </ion-item>

                        <div class="action">
                            <button type="button" class="btn_allega_foto" @click="openModalPhotos()">
                                Carica immagini
                            </button>
                        </div>

                        <div class="actions_firme">
                            <button type="button" class="btn_firma" :class="{ btn_signed: customerHasSigned }" @click="openSignature('cliente')">
                                Firma cliente
                            </button>
                            <button type="button" class="btn_firma" :class="{ btn_signed: technicianHasSigned }" @click="openSignature('tecnico')">
                                Firma tecnico
                            </button>
                        </div>

                        <div class="action">
                            <button type="submit" class="btn_crea_intervento" :disabled="isSubmit">
                                Salva
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonTextarea,
    IonText,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonThumbnail,
    IonIcon,
    loadingController
} from "@ionic/vue";
import { arrowBackOutline, close } from "ionicons/icons";

import { defineComponent, ref, reactive, computed, watch, onMounted } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";

import { openToast } from "@/services/toast";
import apiProgetti from "@/services/progetti";
import apiTickets from "@/services/tickets";
import apiTecnici from "@/services/tecnici";

import ImagesModal from "@/components/images/ImagesModal";
import ModalFirmaIntervento from "@/components/firme/ModalFirmaIntervento";

// Custom
import apiInterventi from "@/custom/services/interventi";
import apiClienti from "@/custom/services/clienti";

export default defineComponent({
    name: "ModalNuovoIntervento",
    props: {
        data: {
            type: Object,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonTextarea,
        IonText,
        IonInput,
        IonSelect,
        IonSelectOption,
        IonThumbnail,
        IonIcon,
    },
    setup(props, context) {
        //console.log(props.data);
        const start_date = new Date();
        const start_hour = new Date();
        const end_hour = new Date();
        start_hour.setHours(start_hour.getHours() - 1);
        end_hour.setHours(end_hour.getHours() + 2);

        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;

        const tecnicoLoggato = ref({});

        const intervento = reactive({
            cliente: null,
            sede_cliente: "",
            progetto: null,
            ticket: null,
            data: start_date.toISOString(),
            ora_inizio: start_hour.toISOString(),
            ora_fine: end_hour.toISOString(),
            tempo_viaggio: "",
            ore_fatturabili: "",
            modalita: "",
            stato_lavori: "",
            tecnici: [],
            descrizione: "",
            materiali_utilizzati: "",
            allegati: [],
            firma_tecnico: "",
            firma_cliente: "",
            tipo_intervento: 2,
            maggiorazione: 1,
        });

        const successResponse = ref(false);

        /**
         * ! HELPERS
         */
        const isSubmit = ref(false);

        const showError = (message) => {
            isSubmit.value = false;
            openToast(message, "toast_danger");
            return;
        };

        /**
         *
         * ! Close modal after form data submit or after click on Chiudi button
         *
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }
        function closeModal() {
            modalController.dismiss();
        }

        /**
         *
         * ! Gestione foto intervento
         *
         */
        const fotoIntervento = ref([]);
        async function openModalPhotos() {
            const modal = await modalController.create({
                component: ImagesModal, //Component to render inside ionic modal
                componentProps: {
                    images: fotoIntervento.value,
                },
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    const foto = detail.data;
                    if (foto) {
                        foto.forEach((singleFoto) => {
                            //console.log(singleFoto);
                            intervento.allegati.push(singleFoto);
                        });
                    }
                }
            });
            return modal.present();
        }

        /**
         *
         * ! Remove photo from the fotoInterventi reference data array
         *
         */
        function deletePhoto(photo) {
            intervento.allegati = intervento.allegati.filter((foto) => foto.name !== photo);
        }

        /**
         *
         * ! Open modal to sign
         *
         */
        const technicianHasSigned = ref(false);
        const customerHasSigned = ref(false);

        async function openSignature(signatureType) {
            const modal = await modalController.create({
                component: ModalFirmaIntervento,
                componentProps: {
                    data: signatureType,
                    firma: signatureType === "tecnico" ? intervento.firma_tecnico : intervento.firma_cliente,
                },
            });
            modal.onDidDismiss().then((detail) => {
                //console.log(detail.data);
                if (detail.data) {
                    //Cliente e/o tecnico hanno firmato, cambia classe al button
                    if (detail.data.firma && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            intervento.firma_tecnico = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                technicianHasSigned.value = true;
                            }
                        } else {
                            intervento.firma_cliente = detail.data.firma;
                            if (detail.data.firma && detail.data.firma.length != 0) {
                                customerHasSigned.value = true;
                            }
                        }
                    }
                    //Firma cliente e/o tecnico è stata pulita e chiusa la modale, devo togliere la firma da intervento
                    if (detail.data.da_cancellare === true && detail.data.tipo) {
                        if (detail.data.tipo === "tecnico") {
                            intervento.firma_tecnico = "";
                            technicianHasSigned.value = false;
                        } else {
                            intervento.firma_cliente = "";
                            customerHasSigned.value = false;
                        }
                    }
                }
            });
            return modal.present();
        }

        /**
         * Load customer
         */
        const customers = ref([]);
        async function loadCustomer() {
            try {
                const res = await apiClienti.getCustomers();
                if (res.status === 0) {
                    customers.value = res.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei clienti", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta dei clienti", "toast_danger");
            } finally {
                //console.log(customers.value);
            }
        }

        /**
         *
         * ! Search customer functionality and selection
         *
         */
        const searchQuery = ref("");
        const normalizeString = (str) => str.toLowerCase().replace(/ /g, "");
        const searchedCustomers = computed(() => {
            const term = normalizeString(searchQuery.value);

            return customers.value.filter((cliente) => {
                const company = cliente.customers_company ? normalizeString(cliente.customers_company) : "";
                const firstName = cliente.customers_name ? normalizeString(cliente.customers_name) : "";
                const lastName = cliente.customers_last_name ? normalizeString(cliente.customers_last_name) : "";
                const fullName1 = firstName + lastName;
                const fullName2 = lastName + firstName;

                return company.includes(term) || firstName.includes(term) || lastName.includes(term) || fullName1.includes(term) || fullName2.includes(term);
            });
        });

        const showCustomer = ref(false);
        function openCustomerSelection() {
            showCustomer.value = true;
        }
        function closeCustomerSelection() {
            showCustomer.value = false;
        }

        const selectedCustomer = ref(null);
        function setSelectedCustomer(customer) {
            //console.log(customer);
            if (customer) {
                searchQuery.value = "";
                selectedCustomer.value = customer;
                showCustomer.value = false;
            }
        }

        const setActiveCustomer = computed(() => (customer, selectedCustomer) => {
            return selectedCustomer && customer.customers_id === selectedCustomer.customers_id ? "active_customer" : "";
        });

        /**
         *
         * ! Get customer shipping address
         *
         */
        const sedi_cliente = ref([]);
        async function loadSediCliente(customer_id) {
            if (customer_id) {
                try {
                    const res = await apiClienti.getSediCliente(customer_id);
                    sedi_cliente.value = res.data;
                } catch (error) {
                    console.error(error);
                    openToast("Si è verificato un errore durante la richiesta delle sedi del ciente", "toast_danger");
                }
            }
        }

        /**
         *
         * ! Get customer projects
         *
         */
        const progetti = ref([]);
        async function loadProgetti(customer_id) {
            try {
                const res = await apiProgetti.getClienteCommesse(customer_id);
                if (res.status === 0) {
                    progetti.value = res.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei progetti", "toast_danger");
                }
            } catch (error) {
                progetti.value = [];
                openToast("Si è verificato un errore durante la richiesta dei progetti", "toast_danger");
            }
        }

        /**
         *
         * ! Modalità intervento
         *
         */
        const modalita_intervento = ref([]);
        async function loadTipologieIntervento() {
            try {
                const res = await apiInterventi.getTipologieIntervento();

                if (res.status === 200 && res.data.status === 0) {
                    modalita_intervento.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta delle tipologie di intervento effettuabili", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta delle tipologie di intervento effettuabili", "toast_danger");
            }
        }
        loadTipologieIntervento();

        /**
         *
         * ! Stati lavoro effettuato
         *
         */
        const stati_lavoro = ref([]);
        async function loadStatiLavoro() {
            try {
                const res = await apiInterventi.getStatoLavori();

                if (res.status === 200 && res.data.status === 0) {
                    stati_lavoro.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta degli stati dei lavori", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta degli stati dei lavori", "toast_danger");
            }
        }
        loadStatiLavoro();

        /**
         *
         * ! Tipologie intervento
         *
         */
        const compareWith = (o1, o2) => {
            return o1 === o2 || o1 == o2;
        };
        /**
         * ? Fix per default value non mostrato fino all'interazione con la select
         * ? https://github.com/ionic-team/ionic-framework/issues/19324
         */
        function showPlaceholderTextTipoIntervento() {
            return "Attività Standard / Teleassistenza";
        }

        const tipi_intervento = ref([]);
        async function loadTipiIntervento() {
            try {
                const res = await apiInterventi.getTipologieInterventi();

                if (res.status === 200 && res.data.status === 0) {
                    tipi_intervento.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta delle tipologie di intervento", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta delle tipologie di intervento", "toast_danger");
            }
        }
        loadTipiIntervento();

        /**
         *
         * ! Maggiorazioni
         *
         */
        /**
         * ? Fix per default value non mostrato fino all'interazione con la select
         * ? https://github.com/ionic-team/ionic-framework/issues/19324
         */
        function showPlaceholderTextMaggiorazione() {
            return "Nessuna";
        }

        const maggiorazioni = ref([]);
        async function loadMaggiorazioni() {
            try {
                const res = await apiInterventi.getMaggiorazioni();

                if (res.status === 200 && res.data.status === 0) {
                    maggiorazioni.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta delle maggiorazioni", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta delle maggiorazioni", "toast_danger");
            }
        }
        loadMaggiorazioni();

        /**
         *
         * ! Tecnici intervento
         *
         */
        function compareWithTecnici(f1, f2) {
            /* console.log(f1, f2);
            console.log("---compareWith---", f2); */
            if (Array.isArray(f2)) {
                if (!f1 || !f1.users_id) {
                    return false;
                }
                return f2.find((val) => val && val.users_id === f1.users_id);
            }
            return f1 && f2 ? f1.users_id === f2.users_id : f1 === f2;
        }

        const tecnici = ref([]);
        async function loadTecnici() {
            try {
                const res = await apiTecnici.getTecnici();
                tecnici.value = res;
                tecnicoLoggato.value = tecnici.value.find((el) => el.users_id === userID);
                if (tecnicoLoggato.value) {
                    intervento.tecnici.push(tecnicoLoggato.value);
                    compareWithTecnici(tecnicoLoggato.value, tecnicoLoggato.value);
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta dei tecnici", "toast_danger");
            }
        }
        loadTecnici();

        function showPlaceholderTextTecnici() {
            return `${tecnicoLoggato.value.users_first_name} ${tecnicoLoggato.value.users_last_name}`;
        }

        /**
         * ! Watch customer selection to get sede and project data
         */

        watch(selectedCustomer, (newCustomer) => {
            loadSediCliente(newCustomer.customers_id);
            loadProgetti(newCustomer.customers_id);
        });

        /**
         * ! Create new intervento
         */
        function printFormValues(formData) {
            for (const value of formData.entries()) {
                console.log(value[0] + ", " + value[1]);
            }
        }
        // Helper function to append data if the value exists
        function appendData(formData, key, value = null, isArray = false, array = [], fieldKey = null) {
            if (!isArray && value && value !== "") {
                formData.append(key, value);
            }
            if (isArray && array.length > 0) {
                array.forEach((el) => {
                    formData.append(key, el[fieldKey]);
                });
            }
        }

        async function creaIntervento() {
            isSubmit.value = true;
            const tecnico_id = userID; //set technician as logged user id

            // Validation
            if (!selectedCustomer.value) return showError("Il cliente è obbligatorio");
            if (!intervento.modalita) return showError("La modalità è obbligatoria");
            if (!intervento.tipo_intervento) return showError("Il tipo di intervento è obbligatorio");
            if (!intervento.maggiorazione) return showError("La maggiorazione è obbligatoria");
            if (intervento.tecnici.length === 0) return showError("Non puoi creare un intervento senza selezionare i tecnici");
            if (!intervento.tecnici.find((tecnico) => tecnico.users_id === userID))
                return showError("Non puoi creare un intervento senza essere tra i tecnici selezionati");
            if (new Date(intervento.ora_inizio) > new Date(intervento.ora_fine)) return showError("L'ora di inizio non può essere maggiore dell'ora di fine");
            if (!intervento.stato_lavori) return showError("Lo stato dei lavori è obbligatorio");

            const loading = await loadingController.create({
                message: 'Salvataggio in corso...',
                duration: 25000,
            });

            await loading.present();

            const data_inizio_formatted = moment(intervento.data_inizio).format("YYYY-MM-DD");
            const ora_inizio_formatted = moment(intervento.ora_inizio).format("HH:mm");
            const ora_fine_formatted = moment(intervento.ora_fine).format("HH:mm");

            const formData = new FormData();

            appendData(formData, "tickets_reports_technician", tecnico_id); //logged user
            appendData(formData, "tickets_reports_customer_id", selectedCustomer.value.customers_id);
            appendData(formData, "tickets_reports_project_id", intervento.progetto ? intervento.progetto : null);
            appendData(formData, "tickets_reports_customer_address", intervento.sede_cliente ? intervento.sede_cliente : null);

            //appendData(formData, "tickets_reports_project_id", props.data.value.tickets_project_id ? props.data.value.tickets_project_id : null);
            //appendData(formData, "tickets_reports_ticket_id", props.data.value.tickets_id ? props.data.value.tickets_id : null);

            appendData(formData, "tickets_reports_tipo_intervento", intervento.tipo_intervento);
            appendData(formData, "tickets_reports_maggiorazione", intervento.maggiorazione);
            appendData(formData, "tickets_reports_date", data_inizio_formatted);
            appendData(formData, "tickets_reports_start_time", ora_inizio_formatted);
            appendData(formData, "tickets_reports_end_time", ora_fine_formatted);
            appendData(formData, "tickets_reports_travel_time", intervento.tempo_viaggio);
            
            // Passo sempre ore fatturabili per avere calcolo automatico con ore di viaggio
            formData.append("tickets_reports_billable_hours", intervento.ore_fatturabili);
            
            //appendData(formData, "tickets_reports_billable_hours", intervento.ore_fatturabili);
            appendData(formData, "tickets_reports_type", intervento.modalita);
            appendData(formData, "tickets_reports_stato_lavori", intervento.stato_lavori);
            appendData(formData, "tickets_reports_tecnici[]", null, true, intervento.tecnici, "users_id");
            // Non required fields
            appendData(formData, "tickets_reports_description", intervento.descrizione);
            appendData(formData, "tickets_reports_used_equipments", intervento.materiali_utilizzati);
            appendData(formData, "tickets_reports_attachments[]", null, true, intervento.allegati, "data");
            appendData(formData, "tickets_reports_firma_operatore_b64", intervento.firma_tecnico);
            appendData(formData, "tickets_reports_firma_cliente_b64", intervento.firma_cliente);

            // Passo sempre flag per inviare email a cliente
            formData.append("tickets_reports_invia_mail", "1");

            //printFormValues(formData);
            //return;

            try {
                const response = await apiInterventi.saveIntervento(formData);
                if (response.data.status === 8) {
                    isSubmit.value = false;

                    loading.dismiss();

                    openToast(response.data.message, "toast_danger");
                } else {
                    const res = response.data.data[0];
                    successResponse.value = true;

                    loading.dismiss();

                    closeModalOnSubmit(successResponse, res);
                }
            } catch (error) {
                isSubmit.value = false;
                console.error(error);
                
                loading.dismiss();

                openToast("Si è verificato un errore durante la creazione dell'intervento", "toast_danger");
            } finally {
                isSubmit.value = false;
                loading.dismiss();
                /* setTimeout(() => {
                    isSubmit.value = false;
                }, 1500); */
            }
        }

        onMounted(() => {
            loadCustomer();
        });

        return {
            closeModal,
            arrowBackOutline,
            close,
            creaIntervento,
            intervento,
            sedi_cliente,
            progetti,
            modalita_intervento,
            stati_lavoro,
            tecnici,
            // Clienti
            searchQuery,
            searchedCustomers,
            showCustomer,
            openCustomerSelection,
            closeCustomerSelection,
            selectedCustomer,
            setActiveCustomer,
            setSelectedCustomer,
            //Allegati (foto)
            openModalPhotos,
            deletePhoto,
            //Firme
            openSignature,
            technicianHasSigned,
            customerHasSigned,
            //Gestione select
            compareWith,
            //Disable button
            isSubmit,
            // Campi personalizzati
            //modalita, --> tickets_reports_type
            tipi_intervento,
            showPlaceholderTextTipoIntervento,
            maggiorazioni,
            showPlaceholderTextMaggiorazione,
            showPlaceholderTextTecnici,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

.field_title.customer_selection {
    display: flex;
    justify-content: space-between;
}

.field_title.customer_selection .trigger {
    font-size: 10px;
    padding: 4px 8px;
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    text-transform: capitalize;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}

ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    margin-top: 0;
    border: 1px solid #d1d5db;
    border-radius: 4px;
}
.custom_input textarea::placeholder {
    font-size: 5px;
}

.btn_crea_intervento {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
}
.btn_crea_intervento:disabled {
    background-color: rgb(75, 186, 116);
}

ion-button {
    --color: #ffffff;
}

.actions_firme {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 16px 0;
}
.btn_firma {
    width: 47%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    background-color: rgb(226 232 240);
    color: rgb(148 163 184);
}

.btn_firma.btn_signed {
    background-color: #086fa3;
    color: #ffffff;
    transition: all 0.15s ease-in;
}

/** Allegati */
.btn_allega_foto {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    background-color: #086fa3;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}

.foto_container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    margin-top: 8px;
    margin-bottom: 16px;
}
.single_foto {
    margin-right: 16px;
    margin-bottom: 16px;
}
ion-thumbnail {
    --size: 120px;
    --border-radius: 4px;
}

.remove_photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border-radius: 4px;
    padding: 4px;
    font-size: 12px;
}

/** Customer selection  */
.fade-transition-enter-active,
.fade-transition-leave-active {
    transition: opacity 0.25s ease;
}

.fade-transition-enter-from,
.fade-transition-leave-to {
    opacity: 0;
}
.customer_modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    padding: 24px;
    align-items: center;
}
.close_modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    color: #ffffff;
    background-color: #086fa3;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    margin-top: 16px;
}
.search_customers {
    width: 100%;
    border: 1px solid #c3c8cf;
    border-radius: 4px;
    padding: 8px;
    margin-bottom: 16px;
}
.search_customers::placeholder {
    color: #6b7280;
}
.search_customers:focus-visible {
    /* outline: 1px solid #509843;*/
    outline: 1px solid #334155;
}
.customers_container {
    width: 100%;
    height: 80%;
    background-color: #ffffff;
    border-radius: 4px;
    color: #000000;
    padding: 12px;
    display: flex;
    flex-direction: column;
}
.customers_list {
    overflow-y: scroll;
}

.customer {
    padding: 6px;
}
.active_customer {
    background-color: #086fa3;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 500;
}

.flex_container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
}
.flex_container .field {
    width: 47%;
}
</style>
