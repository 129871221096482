import axios from "axios";

export default {
    async getAllInterventi(userID: string) {
        const data = new FormData();
        data.append("limit", "100");
        data.append("offset", "0");
        /*         data.append(
            "where[]",
            `tickets_reports_id IN (SELECT tickets_reports_id FROM tickets_reports_tecnici WHERE users_id = '${userID}') || tickets_reports_technician = '${userID}'`
        ); */
        data.append("where[]", `tickets_reports_technician = '${userID}'`);
        data.append("orderby", "tickets_reports_date");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/tickets_reports", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },

    async getInterventi(id: string) {
        const data = new FormData();
        data.append("where[tickets_reports_ticket_id]", id.toString());
        data.append("orderby", "tickets_reports_date");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/tickets_reports", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response.data.data);
        return response.data.data;
    },

    async getTicketDetail(id: string, userID: string) {
        const data = new FormData();
        data.append("where[]", `tickets_id IN (SELECT tickets_id FROM tickets_tecnici WHERE users_id = '${userID}')`);
        data.append("where[tickets_id]", id.toString());
        data.append("depth", "2");

        const response = await axios.post("rest/v1/search/tickets", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response.data.data[0];
    },

    async getTipologieIntervento() {
        const response = await axios.get("rest/v1/search/tickets_reports_type", {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },

    async getStatoLavori() {
        const response = await axios.get("rest/v1/search/tickets_reports_stato_lavori", {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response);
        return response;
    },

    async saveIntervento(data) {
        //const response = await axios.post(`http://192.168.11.141/firegui_gmsystem/rest/v1/create/tickets_reports`, data, {
        const response = await axios.post("rest/v1/create/tickets_reports", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response.data.data[0]);
        //console.log(response);
        return response;
    },

    /**************************************************************
     * New field - 01/09/2022
     */
    /**
     * @param projectID commessa ID
     * @param userID user ID
     * @returns ticket assigned to me for a specified commessa
     */
    async getProjectInterventi(projectID: string) {
        const data = new FormData();
        //data.append("where[]", "tickets_reports <> 5");
        //data.append("where[]", `tickets_id IN (SELECT tickets_id FROM tickets_tecnici WHERE users_id = ${userID.toString()})`);
        data.append("where[tickets_reports_project_id]", projectID);
        data.append("orderby", "tickets_reports_date");
        data.append("orderdir", "desc");

        const response = await axios.post("rest/v1/search/tickets_reports", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        return response.data;
    },

    /**
     *
     * ! CHIAMATE PER CAMPI CUSTOM
     */
    async getTipologieInterventi() {
        const response = await axios.get("rest/v1/search/tickets_reports_tipo_intervento", {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        return response;
    },
    async getMaggiorazioni() {
        const response = await axios.get("rest/v1/search/tickets_reports_maggiorazione", {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        return response;
    },
};
