<template>
    <div class="field">
        <div class="title">{{ title }}</div>
        <div class="value">{{ value }}</div>
    </div>
</template>

<script>
export default {
    name: "InfoField",
    props: {
        title: String,
        value: String,
    },
};
</script>

<style scoped>
.field .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field .value {
    font-size: 14px;
}
</style>
