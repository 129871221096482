<template>
    <div class="field_note description">
        <div class="title">{{ title }}</div>
        <div class="value">{{ value }}</div>
    </div>
</template>

<script>
export default {
    name: "NoteField",
    props: {
        title: String,
        value: String,
    },
};
</script>

<style scoped>
.intervento .field_note {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 12px 6px;
}
.intervento .field_note.description {
    border-bottom: 1px solid #e2e8f0;
}
.intervento .field_note.description:last-of-type {
    border-bottom: none;
}
.field .title,
.field_note .title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.field .ticket_title {
    text-transform: uppercase;
}
.field_note .title {
    margin-bottom: 8px;
}
.field .value,
.field_note .value {
    font-size: 14px;
}
</style>
