<template>
    <div class="field">
        <div class="title">Stato lavori</div>
        <div :class="['badge', statusClass]">{{ value }}</div>
    </div>
</template>

<script>
import { defineComponent, computed } from "vue";

export default defineComponent({
    name: "StatusField",
    props: {
        statusId: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const statusClass = computed(() => {
            return props.statusId == "1" ? "badge_low" : "badge_high";
        });

        return {
            statusClass,
        };
    },
});
</script>

<style scoped>
.field {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 6px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e2e8f0;
}
.title {
    font-size: 14px;
    font-weight: 500;
    color: #475569;
}
.badge {
    font-size: 10px;
    font-weight: 600;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 4px;
}
.badge_low {
    background-color: rgb(220 252 231);
    color: rgb(34 197 94);
}
.badge_high {
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
}
</style>
